;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.statistics')
    .constant('KPIS', [
      {
        key: 'ordersTotal',
        type: 'number',
        title: 'Anzahl Bestellungen',
      },
      {
        key: 'turnoverTotal',
        type: 'currency',
        title: 'Umsatz',
      },
    ])
    .controller('StatisticKpiController', StatisticKpiController)
    .service('StatisticKpiChartService', StatisticKpiChartService)

  function StatisticKpiController(
    KPIS,
    $filter,
    $mdDialog,
    $mdSidenav,
    $q,
    $scope,
    CustomerService,
    StatisticKpiChartService,
    StatisticService,
    UserService
  ) {
    var vm = this

    vm.datasets = []
    vm.kpis = angular.copy(KPIS)

    vm.openDialogDatasets = openDialogDatasets
    vm.openDialogKpis = openDialogKpis

    vm.submitDatasetForm = submitDatasetForm
    vm.editDataset = editDataset
    vm.deleteDataset = deleteDataset
    vm.cancelEditing = cancelEditing

    vm.createCharts = createCharts

    function openDialogDatasets(event) {
      clearDatasetForm()
      $mdSidenav('compare-datasets').open()
    }

    function openDialogKpis(event) {
      $mdSidenav('compare-kpi').open()
    }

    function loadStatistic(event) {
      var customer = $scope.filter.customer
      var stores = $scope.filter.stores.filter((store) => $scope.filter.storeId.includes(store.storeId))
      var systems = customer.customerSystemAssociations
        .map((csa) => csa.system)
        .filter((system) => vm.systemId.includes(system.systemId))
      var dateFrom = $scope.filter.dates.from
      var dateTo = $scope.filter.dates.to

      var params = {
        customerId: customer.customerId,
        storeId: stores.map((store) => store.storeId),
        systemId: systems.map((system) => system.systemId),
        dateFrom: $filter('date')(dateFrom),
        dateTo: $filter('date')(dateTo),
      }
      var hash = JSON.stringify(params)

      if (vm.datasets.find((set) => set.hash == hash)) {
        var alert = $mdDialog
          .alert()
          .title('Duplikat')
          .textContent('Es wurde bereits ein Datensatz für diese Abfrage hinzugefügt.')
          .ok('Okay')
        return $mdDialog.show(alert).then(() => $q.reject())
      }

      return StatisticService.getOrders(params).then((response) => {
        if (response.ordersTotal == 0) {
          var alert = $mdDialog
            .alert()
            .title('Keine Daten')
            .textContent('Für diese Abfrage wurden keine Statistiken gefunden.')
            .ok('Okay')
          return $mdDialog.show(alert).then(() => $q.reject())
        }

        var statistic = vm.kpis.reduce((stat, kpi) => {
          stat[kpi.key] = response.statistics.reduce((sum, s) => sum + (s[kpi.key] || 0), 0)
          return stat
        }, {})

        return {
          hash,
          params: { customer, stores, systems, dateFrom, dateTo },
          statistic,
        }
      })
    }

    function submitDatasetForm(event) {
      var promise
      if (vm.isEditing) {
        promise = loadStatistic(event).then((newDataset) => {
          var oldDataset = vm.datasets.find((set) => set.hash == vm.editDatasetHash)
          if (oldDataset) {
            angular.copy(newDataset, oldDataset) // copy new dataset onto old dataset
          }
        })
      } else {
        promise = loadStatistic(event).then((newDataset) => {
          vm.datasets.push(newDataset)
        })
      }

      promise.then(() => {
        cancelEditing()
        createCharts()
      })
    }

    function editDataset(event, dataset) {
      $scope.filter.customerId = dataset.params.customer.customerId
      $scope.filter.storeId = dataset.params.stores.map((store) => store.storeId)
      vm.systemId = dataset.params.systems.map((system) => system.systemId)
      $scope.filter.dates.from = dataset.params.dateFrom
      $scope.filter.dates.to = dataset.params.dateTo

      vm.isEditing = true
      vm.editDatasetHash = dataset.hash
    }

    function deleteDataset(event, dataset) {
      var confirm = $mdDialog
        .confirm()
        .targetEvent(event)
        .title('Datensatz löschen?')
        .textContent('Möchten Sie diesen Datensatz löschen?')
        .ok('ja')
        .cancel('nein')
      return $mdDialog.show(confirm).then(() => {
        var idx = vm.datasets.indexOf(dataset)
        if (idx > -1) {
          vm.datasets.splice(idx, 1)
          createCharts()
        }
      })
    }

    function cancelEditing() {
      vm.isEditing = false
      clearDatasetForm()
    }

    function clearDatasetForm() {
      if ($scope.filter.showCustomerSelect) {
        $scope.filter.customerId = null
      }
      if ($scope.filter.showCustomerSelect || ($scope.filter.stores && $scope.filter.stores.length > 1)) {
        $scope.filter.storeId = []
      }
      vm.systemId = []
      $scope.filter.dates.from = null
      $scope.filter.dates.to = null

      $scope.datasetForm.$setPristine()
      $scope.datasetForm.$setUntouched()
    }

    function createCharts(singleKpiKey) {
      vm.kpis.forEach((kpi) => {
        if (vm.datasets.length >= 2 && kpi.show) {
          if (!singleKpiKey || singleKpiKey == kpi.key) {
            kpi.chart = StatisticKpiChartService.generateChart('PieChart', vm.datasets, kpi)
          }
        } else {
          delete kpi.chart
        }
      })
    }
  }

  function StatisticKpiChartService($filter) {
    var service = this

    service.generateChart = generateChart

    function generateChart(type, datasets, kpi) {
      var data = new google.visualization.DataTable()

      data.addColumn('string', 'Datensatz')
      data.addColumn('number', kpi.title)

      datasets.forEach((dataset, index) => {
        var value = dataset.statistic[kpi.key] || 0
        data.addRow(['Datensatz ' + (index + 1), { v: value, f: getFormattedValue(kpi.type, value, dataset.params) }])
      })

      var options = {
        backgroundColor: 'transparent',
        fontName: 'Roboto',
        fontSize: 14,
        height: 400,
        is3D: true,
        legend: {
          alignment: 'center',
        },
        sliceVisibilityThreshold: 0,
        title: kpi.title + ' pro Datensatz',
        titleTextStyle: {
          fontSize: 16,
        },
      }

      return { type, data, options }
    }

    function getFormattedValue(filter, value, params) {
      switch (filter) {
        case 'currency':
          return $filter(filter)(value, params.customer)
        default:
          return $filter(filter)(value)
      }
    }
  }
})()
